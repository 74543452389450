@keyframes fadein {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}
.request-text {
  display: none;
}


.searchResult {
  position: relative;
  flex-grow: 2;
  overflow-y: auto;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $bg-standard;

  @media screen and (max-width: 600px) and (min-width: 1000px) {
    z-index: calc(#{$divider_z_index} + 2);
  }

  &.background-overlay {
    @media screen and (max-width: 600px) {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .replaceable_result {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;

    &.background-overlay {
      @media screen and (max-width: 600px) {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  @media screen and (max-width: map_get($breakpoints, medium)) {
    &__items {
      margin-right: 0;
    }
    margin-left: 0;
    padding-left: 20px;
  }

  @media screen and (max-width: map_get($breakpoints, small)) {
    &__items {
      margin-right: 0;
    }
    padding-left: 20px;
    margin-left: 0;
  }


  .result-div {
    width: inherit;

    &.own_scroll {
      max-height: 100vh;
      overflow-y: auto;
      position: relative;
      overscroll-behavior-y: contain;
      padding-left: 2px;
    }

  }

  &__theme {
    margin: 0 0 20px 90px;
    font-size: 18px;
    font-weight: $fw-700;
    text-align: left;
  }

  &__active_filters {
    margin: 10px 0 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    white-space: nowrap;
    flex-wrap: wrap;

    &__filter_tag {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 13px 0 15px;
      margin-right: 5px;
      margin-bottom: 5px;

      background: $shade-light;
      border-radius: 22px;
      height: 40px;

      max-width: 100%;

      &--simple {
        background: transparent;
        border: 1px solid $shade-color;
      }

      &__display {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-width: 0;
        max-width: 100%;

        &__category {
          display: flex;
          height: 12px;
          left: 0;
          right: 90px;
          align-items: center;
          font-style: $fs-standard;
          font-size: $fz-10;
          line-height: $lh-14;
          color: $shade-dark;
        }

        &__text {
          display: flex;
          align-items: center;
          text-align: center;

          font-weight: $fw-600;
          font-size: $fz-14;
          line-height: 18px;

          margin: 0 2px;

          min-width: 0;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &--simple {
          font-weight: $fw-standard;
        }
      }


      button {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &__summary {
    margin: 0 0 10px;
    text-align: left;


    &__articlesFound {
      position: absolute;
      top: 5px;
      margin-top: 15px;
      font-size: $fz-22;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      @media screen and (max-width: map_get($breakpoints, medium)) {
        position: initial;
        top: initial;
      }

      &__count {
        font-style: $fs-standard;
        font-weight: $fw-700;
        font-size: $fz-standard;
        line-height: $lh-30;
        margin: 0 5px 0 0;
      }

      &__where {
        margin-right: 15px;

      }

      &__search {
        font-weight: $fw-standard;
        font-size: $fz-standard;
        line-height: $lh-standard;
        padding-top: 4px;

        p {
          color: $primary-color;
          text-decoration: none;
        }
      }
    }

    &__error {
      font-size: $fz-42;
      line-height: $lh-57;
      font-weight: $fw-700;

      @media screen and (max-width: map_get($breakpoints, medium)) {
        font-size: $fz-32;
        line-height: $lh-43;
      }
    }

    &__menu {
      margin-top: 15px;

      &__hitlist {

        min-height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: map_get($breakpoints, medium)) {
          flex-direction: column;
          align-items: start;
        }

        &__left {

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          p {
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            margin-right: 15px;
          }
        }

        &__right {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-right: 20px;
          position: relative;

          &__button {
            cursor: pointer;
            margin-right: 17px;
          }

          &__list {
            white-space: nowrap;
            display: flex;

            p {
              font-weight: normal;
              font-size: 16px;
              line-height: 19px;
              margin-top: 0;
              margin-bottom: 0;
            }
          }


        }

      }

    }

    strong {
      color: $fg-standard;
    }

    &__recommendations {

    }
  }

  .list-view-wrapper {
    width: 100%;
    padding-right: 20px;

    .right-panel {
      width: 28px;
      margin: 10px 0;
    }
  }

  &.left_aligned {
    .list-view-wrapper {
      .right-panel {
        @media screen and (max-width: map_get($tile-breakpoints, 'fourTiles')) {
          width: 28px;
        }
      }
    }
  }

  .list-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &__articles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 10px 0;
  }

  &__pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 5px;
  }


  &__media-items {
    margin-bottom: 50px;
  }

  &__media-items-flex {
    margin-bottom: 50px;
    display: inline-flex;
    width: 100%;
  }
}

.partialSearchResult {

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.list-active .grid-view-switcher {
  display: none;
}

.grid-active .list-view-switcher {
  display: none;
}

@media screen and (max-width: map_get($breakpoints, small)) {
  .searchResult {
    padding-left: 20px;

    .result-div {
      padding-left: 0;
    }
  }
}

.source-result-filters {
  margin-bottom: 40px;
  column-gap: 25px;
  transition: all 0.2s ease-in-out;

  &:empty {
    margin: 0;
  }
}

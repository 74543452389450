// -----------------------------------------------------------------------------
// This file contains all application-wide CSS and Sass variables.
// -----------------------------------------------------------------------------

//define temp variables in current files

//colors from Brand Library
//main
$bg-standard: var(--background-color); //background-standard
$fg-standard: var(--foreground-color); //foreground-standard
$bg-blocker: var(--color-block); //opening menu or popups
$bg-transparent: transparent;

//tertiary

//primaries
$primary-color: var(--primary-color);
$primary-dark: var(--primary-dark);
$primary-darker: var(--primary-darker);
$primary-dark-transparent: rgba(236, 113, 18, 0.2);
$primary-light: var(--primary-light);
$text-over-primary-color: var(--text-over-primary-color);

//secondaries
$secondary-color: var(--secondary-color);
$secondary-dark: var(--secondary-dark);
$secondary-darker: var(--secondary-darker);
$secondary-dark-transparent: rgba(68, 121, 190, 0.2);
$secondary-light: var(--secondary-light);

//shade
$shade-light: var(--shade-light);
$shade-color: var(--shade-color);
$shade-transparent: rgba(189, 189, 189, 0.2);
$shade-dark: var(--shade-dark);
$shade-darker: var(--shade-darker);

//dangers
$danger-color: var(--danger-color);
$danger-dark: var(--danger-dark);
$danger-darker: var(--danger-darker);

//fonts
$font-family: Open-Sans, Arial, Helvetica, sans-serif;

//font-style
$fs-standard: normal;
$fs-italic: italic;

//font-size
$fz-10: 10px;
$fz-12: 12px;
$fz-14: 14px;
$fz-standard: 16px;
$fz-22: 22px;
$fz-26: 26px;
$fz-32: 32px;
$fz-42: 42px;

//line-height
$lh-14: 14px;
$lh-16: 16px;
$lh-19: 19px;
$lh-standard: 22px;
$lh-30: 30px;
$lh-32: 32px;
$lh-43: 43px;
$lh-57: 57px;

//font-weight
$fw-standard: 400; //normal
$fw-600: 600;
$fw-700: 700; //bold
$fw-800: 800;

//font
$standard-font: 16px/22px $font-family;

//radius's
$border-radius-10: 10px;
$border-radius-20: 20px;
$border-radius-22: 22px;

//button secondary colors
$btn-secondary-focus: var(--btn-secondary-focus);

//colors for documents?
$light-color: #E0E0E0;
$gray: #AFAFAF;
//borders
$standard-border: 1px solid $shade-color;
$light-border: 1px solid var(--border-light);
$border-light-color: var(--border-light);
$outline: 3px solid $secondary-color;

//margin?
$side-space: 80px;
$item-margin: 20px;

//shadows
$active-shadow: var(--active-shadow);
$dropdown-shadow: var(--dropdown-shadow);
$standard-shadow: var(--standard-shadow); //only header

//transitions
$transition-duration: 0.1s;
$preview_open_duration: 0.1s;

//$genios-logo: "/icons/Genios_Weihnachten2022.svg";

// breakpoints map
// breakpoints here differ from figma design to keep them in line with the number of tiles breakpoints
$breakpoints: (
        'xxs': 400px,
        'xs': 485px,
        'small' : 680px,
        'medium': 1000px,
        'large': 1500px,
        'xlarge': 1900px,
        'xxlarge': 2150px
) !default;

$tile-breakpoints: (
        'oneTile' : 400px,
        'twoTiles' : 680px,
        'threeTiles' : 1000px,
        'fourTiles' : 1250px,
        'fiveTiles' : 1500px,
        'sixTiles': 1750px,
        'sevenTiles': 1900px,
        'eightTiles': 2150px,
        'nineTiles': 2400px,
) !default;

:root {
  //main
  --foreground-color: black; //foreground
  --background-color: white; //background
  --color-block: rgba(0, 0, 0, 0.25);

  //primaries
  --primary-color: #F98F17;
  --primary-dark: #F38014;
  --primary-darker: #EC7112;
  --primary-light: #FEE1B4;
  --text-over-primary-color: white;

  //secondaries
  --secondary-color: #7F9ED0;
  --secondary-dark: #4479BE;
  --secondary-darker: #005EB1;
  --secondary-light: #EAF0FF;

  //shade
  --shade-light: #F5F5F5;
  --shade-color: #BDBDBD;
  --shade-dark: #757575;
  --shade-darker: #424242;

  //danger
  --danger-color: #CC1D33;
  --danger-dark: #BF152C;
  --danger-darker: #B00020;

  //borders
  --border-light: rgba(0, 0, 0, 0.1);

  //buttons
  --btn-secondary-focus: #E9E9E9;

  //shadows
  --active-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  --standard-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  --dropdown-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  //ion-icons
  --ionicon-stroke-width: 18px;

  //tiles
  --tiles: 6;
  --fudge1: 105px;
  --fudge2: 20px;

  --vh: 1%;

  @media screen and (max-width: map_get($tile-breakpoints, 'fiveTiles')) {
    --tiles: 5;
  }

  @media screen and (max-width: map_get($tile-breakpoints, 'fourTiles')) {
    --tiles: 4;
  }

  @media screen and (max-width: map_get($tile-breakpoints, 'threeTiles')) {
    --tiles: 3;
    --fudge1: 40px;
  }

  @media screen and (max-width: map_get($tile-breakpoints, 'twoTiles')) {
    --tiles: 2;
  }

  @media screen and (min-width: map_get($tile-breakpoints, 'sevenTiles')) {
    --tiles: 7;
  }

  @media screen and (min-width: map_get($tile-breakpoints, 'eightTiles')) {
    --tiles: 8;
  }

  @media screen and (min-width: map_get($tile-breakpoints, 'nineTiles')) {
    --tiles: 9;
  }

  --tile-width-calc: calc(((100vw - (var(--fudge1))) / var(--tiles) - 1px) - var(--fudge2));
  --collapsed_sidenav_size: 100px;
  --open-sidenav-size: calc(var(--collapsed_sidenav_size) + var(--tile-width-calc) + 20px);
  --open-button-sidenav-position: calc(var(--collapsed_sidenav_size) + var(--tile-width-calc) + 5px)
}


// - sizes for tile layout -
$tile-width-calc: calc(((100vw - (var(--fudge1))) / var(--tiles) - 1px) - var(--fudge2));
$collapsed_sidenav_size: var(--collapsed_sidenav_size);
$open-sidenav-size: var(--open-sidenav-size);
$open-sidenav-size-ssr:  calc(#{$open-sidenav-size} + 13px);
$open-button-sidenav-position: var(--open-button-sidenav-position);

$preview_width: calc(#{$tile-width-calc} * 2 + 35px);
$preview_content_width: calc(#{$preview_width} - 60px);

//------------------- medium results ------------------------------
$medium-sidenav-size: 100vw;
$open-sidenav-mobile-size: calc(#{$medium-sidenav-size} - 60px);

$preview_width_medium: 100vw;
$preview_content_width_medium: calc(#{$preview_width_medium} - 60px);

$preview_width_small: 100vw;
$preview_content_width_small: calc(#{$preview_width_small} - 40px);

$divider_z_index: 100;


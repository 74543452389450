.popup {
  &__standard {
    position: absolute;
    top: 30px;
    background-color: $bg-standard;
    color: $fg-standard;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    border-radius: $border-radius-20;
    box-shadow: $active-shadow;
    z-index: 1;
    max-width: 420px;
    width: calc(100% - 20px);
    cursor: initial;

    .spinner {
      margin: 0;
    }

    &:focus-visible {
      outline: $outline;
    }
  }
}
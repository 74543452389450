.sidePanel {
  button {

    &:hover, &.active {
      text-decoration: none;
    }

    &:active {
      font-weight: normal;
    }

  }

  .os-scrollbar-vertical {
    right: -1px;
    padding: 0 1px 0 0;
  }

  .open-enter-active, .open-leave-active {
    transition-duration: $transition-duration;
  }

  .open-enter, .open-leave-to {
    opacity: 0;
  }

  @media screen and (max-width: map_get($breakpoints, medium)) {
    &.display-on-top {
      z-index: calc(#{$divider_z_index} + 3) !important;

      .searchResult__filters {
        bottom: 0 !important;
      }
    }
  }
}

.sidePanel:not(.display-on-top) {
  @media screen and (max-width: 1254px) {
    .searchResult__filters {
      .sidenav {
        height: 0;
      }
    }
  }
}

.searchResult__filters {
  z-index: 15;
  max-width: $open-sidenav-size;
  display: block;
  top: 105px;
  bottom: 0;

  .sidenav {

    max-width: $open-sidenav-size;
    width: $open-sidenav-size;
    height: 100%;
    position: sticky;
    top: 124px;
    left: 0;
    background-color: $bg-standard;
    overflow-y: auto;
    overscroll-behavior-y: contain;

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      cursor: pointer;
      padding: 35px 0 35px 30px;

      &__advanced_search {
        cursor: pointer;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        margin: 14px 13px;
        height: 50px;
        padding-left: 7px;
        justify-content: flex-start;

        &__icon {
          flex: none;
          order: 0;
          flex-grow: 0;
        }

        p {
          font-style: $fs-standard;
          font-weight: $fw-600;
          font-size: $fz-standard;
          line-height: $lh-standard;
          flex: none;
          order: 1;
          flex-grow: 1;

        }

        @media screen and (max-width: map_get($breakpoints, medium)) {
          padding-left: 20px;
          max-width: 900px;
          margin-left: auto;
          margin-right: auto;
        }

      }

      @media screen and (max-width: map_get($breakpoints, medium)) {
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__text {
      font-weight: bold;
      font-size: $fz-standard;
      line-height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: calc(100% - 50px);


      &__icon {
        flex: 0 0 auto;
      }

      p {
        flex: 1 1 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

    }

    &__toggle {
      cursor: pointer;
      margin-right: 30px;

      ion-icon {
        visibility: visible;
        font-size: 40px;
      }

      &__icon {
        @media screen and (max-width: map_get($breakpoints, medium)) {
          display: none;
        }

      }

      &__show_button {
        display: none;

        z-index: 2;
        @media screen and (max-width: map_get($breakpoints, medium)) {
          display: inline-block;
        }
      }
    }


    .selected-item {
      font-weight: $fw-700;
      color: $primary-color;
    }

    @media screen and (max-width: map_get($breakpoints, medium)) {
      max-width: $open-sidenav-mobile-size;
      width: $open-sidenav-mobile-size;
    }
  }


  ul.archive {
    margin: 10px 0;

    li {
      display: inline-block;
      list-style-type: none;
      padding: 5px;
    }

    li.selected {
      background-color: $primary-darker;
    }
  }


  ul.archive {
    margin: 10px 0;

    li {
      display: inline-block;
      list-style-type: none;
      padding: 5px;
    }

    li.selected {
      background-color: $primary-darker;
    }
  }


  img#more-filters {
    margin: 0;
  }

  button#more-filters {
    width: 16px;
    height: 16px;
  }

  .filter__footer {
    padding: 20px;

    .filter__clear {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      color: $danger-color;

      font-weight: $fw-standard;
      font-size: $fz-standard;
      line-height: $lh-standard;

      &:hover {
        text-decoration: underline;
      }

      &:active {
        text-decoration: underline;
        font-weight: $fw-600;
      }

      span {
        margin-right: 5px;
      }

      @media screen and (max-width: map_get($breakpoints, medium)) {
        margin-left: auto;
        margin-right: auto;

        width: 100%;
        max-width: 900px;
      }

    }
  }

  .form_element {
    &__range_line {
      font-size: $fz-14;
      line-height: $lh-19;

      &__element {
        padding-left: 10px;
        padding-right: 8px;
        margin-top: 2px;
        height: 40px;
        min-width: 8em;
        overflow: hidden;
      }
    }

  }

  @media screen and (max-width: map_get($breakpoints, medium)) {
    top: 150px;
  }

  @media screen and (max-width: map_get($breakpoints, small)) {
    max-width: 0;
  }
}

.filters {
  white-space: nowrap;

  .filter {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    border-bottom: $light-border;

    &:last-of-type {
      border-bottom: none;
    }

    & > div {
      padding: 5px 30px 5px 30px;
    }

    &__title {
      display: grid;
      grid-template-columns: 1fr 24px;
      align-items: center;
      width: 100%;
      padding: 12px 0 12px 0;
      margin: 10px 0;
      cursor: pointer;

      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;

        &:hover {
          background: transparent !important;
        }

        ion-icon {
          visibility: visible;
        }
      }

      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: $fw-700;
        font-size: 16px;
        line-height: 22px;
        flex: none;
        order: 0;
        flex-grow: 1;
        margin: 0 10px 0 0;

      }

    }

    &__item_search {
      align-self: stretch;
      flex-grow: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__box {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2px 14px;
        border: $standard-border;
        box-sizing: border-box;
        border-radius: 25px;
        flex: none;
        order: 3;
        align-self: stretch;
        flex-grow: 0;

        &:focus-within {
          box-shadow: $active-shadow;
        }

        input {
          border: 0;
          background: none;
          margin: 0 5px;
          color: $shade-darker;
          font-size: 14px;
          line-height: 20px;
          width: 100%;
          padding: 0;

          &:focus {
            outline: none;
            color: $shade-darker;
          }

          &:focus::placeholder {
            outline: none;
            color: $shade-dark;
          }

          &::placeholder {
            outline: none;
            color: $shade-darker;
          }

        }

        .button__icon {

          &:hover {
            background: none;
            border: 0 solid rgba(0, 0, 0, 0) !important;
          }
        }
      }
    }

    &__item_container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .form_element {
        margin-bottom: 10px;
        &__label {
          gap: 10px;
          &__text {
            display: inline-flex;
            font-size: 14px;
            white-space: normal;
          }
        }
      }

      &__list {
        width: inherit;
        margin-top: 10px;
      }

      &__date_range_select {
        position: absolute;
        top: 52px;
        z-index: 100;
        font-size: $fz-14;
        line-height: $lh-19;

        ion-icon {
          visibility: visible;
        }

        &__selected {
          display: flex;
          align-items: center;
        }

        &__options {
          position: absolute;
          box-shadow: $active-shadow;
          border-radius: 1rem;
          background-color: $bg-standard;
          z-index: 100;
          top: 2.5rem;
          overflow: hidden;

          &__option {
            display: flex;
            width: 100%;
            padding: 5px 10px;

            &:hover {
              background-color: $primary-color;
              color: white;
            }

            &:first-of-type {
              padding-top: 10px;
            }

            &:last-of-type {
              padding-bottom: 10px;
            }
          }
        }
      }

      &__chart {
        width: 100%;
        height: 248px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &__buttons {
          opacity: 0;
          position: absolute;
          display: flex;
          justify-content: space-between;
          top: 0;
          left: -1.5em;
          height: 100%;
          width: calc(100% + 3em);
          color: $shade-color;

          &:hover {
            opacity: 1;
            color: $fg-standard;
          }
        }

        &:hover {
          & .filter__item_container__chart__buttons {
            opacity: 1;
          }
        }
      }

      &__bottom_buttons {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 8px;

        width: 100%;

        button {
          font-size: $fz-14;
          line-height: $lh-19;
          margin-left: 2px;
          margin-right: 2px;
          margin-bottom: 4px;
          flex-grow: 1;
          text-overflow: ellipsis;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          ion-icon {
            font-size: $fz-14;
            margin-right: 1px;
          }

          ion-icon[name="radio-button-on-outline"] {
            color: $primary-color;
          }

          &:disabled {
            cursor: not-allowed;
            color: $shade-color;

            ion-icon[name="radio-button-on-outline"] {
              color: $shade-color;
            }
          }
        }
      }

      &__placeholder {
        height: 1rem;
      }

      &__spinner_container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: inherit;
        background-color: $bg-standard;
        opacity: 70%;
        display: flex;
        justify-content: center;
        align-items: center;

        .spinner {
          opacity: 1;
        }
      }
    }

    .grow-enter-active, .grow-leave-active {
      transition: all $transition-duration ease-out;
    }

    .grow-enter, .grow-leave-to /* .fade-leave-active below version 2.1.8 */
    {
      opacity: 0;
      max-height: 0;
    }

    .flip-list-enter-active, .flip-list-leave-active {
      transition: all $transition-duration ease-out;
    }

    .flip-list-enter, .flip-list-leave-to /* .list-leave-active below version 2.1.8 */
    {
      opacity: 0;
      max-height: 0;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }


    .flip-list-move {
      transition: transform $transition-duration ease-in-out;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      padding: 6.5px 0;
      width: 100%;
      overflow: hidden;
      cursor: pointer;
      outline-style: none;
      font-size: $fz-standard;

      &.deactivated {
        cursor: default;
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      &__name {
        max-width: calc(100% - 4rem);
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 19px;
        white-space: normal;
        flex: none;
        order: 0;
        flex-grow: 0;


        a, span {
          white-space: normal;

          .checked {
            font-weight: $fw-600;
          }

          @media screen and (max-width: map_get($breakpoints, medium)) {
            max-width: unset;
          }
        }


        span.deactivated {
          color: $shade-color;
          cursor: default;
        }
      }

      &__count {
        margin: 0 0 0 10px;
        display: inline;
        flex-direction: row;
        align-items: center;
        height: 19px;
        font-size: $fz-12;
        line-height: $lh-16;
        margin-top: 3px;


        span {
        }
      }

      &__checkbox {
        flex-grow: 0;
        margin-right: 10px;

        &__icon {
          display: flex;
          color: $fg-standard;

          &-checked {
            display: flex;
            color: $primary-color;
          }
        }
      }

    }

    &__show-more {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 0;
      flex: none;
      order: 5;
      flex-grow: 0;
      margin: 0 0 5px;
      width: 100%;
      cursor: pointer;

      color: $primary-color;

      &__name {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 19px;
        flex: none;
        order: 0;
        flex-grow: 0;
      }

      &__toggle {
        height: 24px;
        width: 24px;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0 5px;

        ion-icon {
          visibility: visible;
        }
      }

      &:hover {
        text-decoration: underline;
      }

      &:active {
        text-decoration: underline;
        font-weight: $fw-700;
      }
    }

    &__content {
      max-height: 0;
      transition-duration: $transition-duration;
      overflow: hidden;
    }

    &__content.not-visible {
      display: none;
    }

  }

  .individual-filter {
    background-color: $shade-light;
    border: none;

    .filter__title {
      color: $primary-color;
    }

    & > div {
      padding: 5px 30px 5px 30px;

      & > p {
        font-weight: bold;
        font-size: $fz-standard;
        line-height: 30px;
      }
    }
  }

  .filter-tree {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;

    list-style: none;
    white-space: nowrap;

    a {
      text-decoration: none;
      color: inherit;
    }

    &__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 15px;

    }

    &__name {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      font-size: 14px;
      line-height: 19px;

      p {
        margin-top: 0;
        margin-bottom: 0;
      }

      span.deactivated {
        color: $shade-color;
      }
    }

    &__count {
      margin-left: 5px;
      margin-top: 9px;
      margin-bottom: 9px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 10px;
      line-height: 14px;
    }


    &__show-more {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      line-height: 19px;
      color: $primary-color;
      padding: 10px 15px 0;

      &__name {
        padding-bottom: 5px;
      }

      &__toggle {
        margin-left: 10px;
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &__hidden {
      visibility: hidden;
      max-height: 0;
      transition-duration: $transition-duration;
    }

  }

  &__clear {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 15px 0;

    button {
      flex: 1;
    }
  }

  &__content {
    max-height: 0;
    transition-duration: $transition-duration;
    overflow: hidden;
  }

  &__content.not-visible {
    display: none;
  }

  @media screen and (max-width: map_get($breakpoints, medium)) {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
}


.sourceFilterArea {
  width: 400px;
  min-width: 150px;
}

.date_filter_chart {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: inherit;
    position: relative;

    width: 100%;
    overflow: hidden;
  }

  &__tooltip {
    cursor: default;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    width: fit-content;
    position: absolute;
    right: 0;
    top: 0;

    opacity: 0;

    transition: all 200ms ease-in-out;

    &-show {
      opacity: 1;
    }

    &__key {
      font-size: $fz-12;
      line-height: $lh-16;
      color: $shade-color;
    }

    &__value {
      font-size: $fz-14;
      line-height: $lh-19;
      font-weight: $fw-600;
    }
  }

  &__column {
    &__background {
      fill: transparent;
      cursor: pointer;

      &-selected {
        fill: $shade-transparent;
      }

      &-preselected {
        fill: $primary-dark-transparent;
        cursor: crosshair;
      }
    }

    &__bar {
      transition: all 30ms ease-in-out;
      fill: $secondary-color;
      cursor: pointer;

      &-selected {
        fill: $primary-color;
      }

      &-preselected {
        fill: $primary-dark;
        cursor: crosshair;
      }
    }

    &:hover {
      & .date_filter_chart__column__background {
        fill: $secondary-dark-transparent;
      }

      & .date_filter_chart__column__background-selected {
        fill: $primary-dark-transparent;
      }

      & .date_filter_chart__column__bar {
        fill: $secondary-dark;

        &-selected {
          fill: $primary-dark;
        }
      }
    }
  }

  &__axis, &__range {
    stroke: $shade-color;
    fill: $shade-dark;
    font-size: $fz-12;
    transition: all 100ms ease-in-out;

    &-text {
      stroke-width: 0;
    }
  }

  &__range {
    color: $shade-dark;
    display: flex;
    justify-content: space-between;
    margin-top: -8px;
  }
}

#sidePanelComponent {
  transition: width 0.5s ease;

  .placeholder {
    display: flex;
    flex-direction: column;
    align-content: center;
    height: 100%;
    max-width: $open-sidenav-size-ssr;
    width: $open-sidenav-size-ssr;

    @media screen and (max-width: 1050px) {
      max-width: 100vw;
      width: 100vw;
    }
  }
}

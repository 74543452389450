.email_group {
  font-weight: $fw-standard;
  font-size: $fz-14;
  line-height: $lh-19;
  color: $shade-dark;
  position: relative;
  z-index: 2;
  margin-bottom: 10px;

  .form_element__label {
    cursor: pointer;
  }

  &__text {
    text-decoration: underline;
    cursor: pointer;

    @media screen and (max-width: map-get($breakpoints, "xs")) {
      display: block;
    }
  }

  &__actions {
    border-top: 1px solid $shade-light;
    display: flex;
    justify-content: flex-end;
    padding: 20px 20px 0 20px;
    margin: 0 -20px;
    column-gap: 10px;
  }

  &__title {
    margin: 0;
  }

  &__item {
    display: flex;
    justify-content: space-between;
  }

  &__data {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .dropdown {
      max-width: unset;
    }

    .checkbox {
      margin: 0;

      &:focus-within {
        span[class^="checkbox-"] {
          box-shadow: none;
        }
      }

      .form_element__label {
        justify-content: space-between;
        column-gap: 0;

        &__text {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        span[class^="checkbox-"] {
          background-size: cover;
        }
      }
    }


    .checked {
      font-weight: $fw-700;
    }
  }

  &__message {
    margin: 0;
  }

  &__disable {
    color: $shade-color;
    user-select: none;
  }

  &__list {
    z-index: 1;
    width: 60%;

    .checkbox.checked {
      font-weight: $fw-standard;
    }

    .form_element {

      &__label {
        cursor: pointer;
        justify-content: space-between;
        flex-direction: row-reverse;

        &__text {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}